import React, { useEffect, useMemo, useState } from 'react';
import {
  makeStyles,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Card,
  CardMedia,
  CardContent,
  Grid,
} from '@material-ui/core';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { IContent } from '@/types';
import { CardActionArea } from 'gatsby-theme-material-ui';

const useCardStyles = makeStyles((theme) => ({
  bottomBar: {
    backgroundColor: ({ secondaryColor }: CardStyleProps) => secondaryColor,
    height: `5%`,
  },
  card: {
    position: `relative`,
    height: theme.spacing(50),
    display: `flex`,
    borderRadius: 0,
    margin: `0 auto`,
    flexDirection: `column`,
    [theme.breakpoints.down(`sm`)]: {
      height: theme.spacing(40),
    },
    [theme.breakpoints.down(`xs`)]: {
      maxWidth: theme.spacing(50),
      minHeight: `unset`,
    },
  },
  cardActionArea: {
    display: `flex`,
  },
  cardContent: {
    background: ({ mainColor }: CardStyleProps) => mainColor,
    color: ({ textColor }: CardStyleProps) => textColor,
    display: `flex`,
    flexDirection: `column`,
    height: `50%`,
    justifyContent: `center`,
    padding: theme.spacing(4, 3),
    textAlign: `center`,
    width: `100%`,
    [theme.breakpoints.down(`sm`)]: {
      height: `55%`,
    },
  },
  cardMedia: {
    flexGrow: 1,
    display: `flex`,
    height: `45%`,
    [theme.breakpoints.down(`sm`)]: {
      height: `40%`,
    },
  },
  collapsedCard: {
    width: `100%`,
    [theme.breakpoints.down(`xs`)]: {
      maxWidth: theme.spacing(50),
    },
  },
  flexFill: {
    flexGrow: 1,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}));

const useRootStyles = makeStyles((theme) => ({
  root: {
    margin: `0 auto`,
    maxWidth: theme.breakpoints.width(`lg`),
    padding: theme.spacing(0, 8),
    [theme.breakpoints.down(`md`)]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
    [theme.breakpoints.down(`sm`)]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
}));

interface CardStyleProps {
  mainColor: string;
  secondaryColor: string;
  textColor: string;
}

interface ActionCardProps extends IContent {
  collapseCard: boolean;
  mainColor: string;
  secondaryColor: string;
  textColor: string;
}

const ActionCard: React.FC<ActionCardProps> = ({
  collapseCard,
  image,
  mainColor,
  secondaryColor,
  subtitle,
  textColor,
  title,
}) => {
  const classes = useCardStyles({ mainColor, secondaryColor, textColor });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down(`sm`));
  const collapse = collapseCard && isSmall;
  return (
    <CardActionArea className={classes.cardActionArea} to="/">
      <Card
        className={collapse ? classes.collapsedCard : classes.card}
        elevation={0}
      >
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title} variant="h5">
            {title}
          </Typography>
          <Typography variant="body2" component="p">
            {subtitle}
          </Typography>
        </CardContent>
        {!collapse && (
          <CardMedia className={classes.cardMedia}>
            <GatsbyImage
              className={classes.flexFill}
              image={image}
              alt={title}
            />
          </CardMedia>
        )}
        <div className={classes.bottomBar} />
      </Card>
    </CardActionArea>
  );
};

const useTransitionDelays = () => {
  const theme = useTheme();
  const [delays, setDelays] = useState<number[]>([0, 500, 1000]);
  useEffect(() => {
    if (theme.breakpoints.values.sm >= window.innerWidth) {
      setDelays([0, 0, 0]);
    } else if (theme.breakpoints.values.md >= window.innerWidth) {
      setDelays([0, 500, 0]);
    }
  }, []);
  return delays;
};

interface ActionCardsProps {
  actionCards: ActionCardProps[];
}

const ActionCards: React.FC<ActionCardsProps> = ({ actionCards }) => {
  const { t } = useTranslation();
  const classes = useRootStyles();
  const delays = useTransitionDelays();
  const theme = useTheme();
  const cardContentColors = useMemo(
    () => [
      theme.palette.grey.main,
      theme.palette.primary.main,
      theme.palette.secondary.main,
    ],
    [theme],
  );
  const cardBottomBarColors = useMemo(
    () => [
      theme.palette.secondary.main,
      theme.palette.secondary.light,
      theme.palette.primary.main,
    ],
    [theme],
  );

  return (
    <Box className={classes.root}>
      <Grid container spacing={3}>
        {actionCards.map(({ title, subtitle, image }, i) => (
          <Grid item xs={12} sm={i === 2 ? 12 : 6} md={4} key={title}>
            <ActionCard
              title={t(title)}
              subtitle={t(subtitle)}
              image={getImage(image)}
              textColor={i === 0 ? `#000` : `#fff`}
              mainColor={cardContentColors[i]}
              secondaryColor={cardBottomBarColors[i]}
              delay={delays[i]}
              collapseCard={i === 2}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ActionCards;
