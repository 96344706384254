import React from 'react';
import { navigate } from 'gatsby';
import { makeStyles, Box, Button, Typography } from '@material-ui/core';
import { IContent } from '@/types';
import ImageContent from '@/components/ImageContent';

const useStyles = makeStyles((theme) => ({
  bodyText: {
    margin: `0 auto`,
    marginBottom: theme.spacing(2),
    maxWidth: theme.spacing(60),
    lineHeight: theme.spacing(0.25),
  },
  greeting: {
    marginBottom: theme.spacing(2),
    fontFamily: `Nickainley`,
    fontSize: theme.spacing(5),
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(4),
    },
  },
  root: {
    alignItems: `start`,
    display: `flex`,
    flexDirection: `column`,
    [theme.breakpoints.down(`sm`)]: {
      alignItems: `center`,
    },
  },
  subtitle: {
    color: `#586350`,
    marginBottom: theme.spacing(3),
    textAlign: `center`,
    [theme.breakpoints.down(`sm`)]: {
      marginBottom: 0,
      '&:after': {
        content: `""`,
        margin: `0 auto`,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: `block`,
        width: theme.spacing(10),
        height: `1px`,
        background: theme.palette.primary.main,
      },
    },
  },
  title: {
    textAlign: `center`,
  },
}));

const Introduction: React.FC<IContent> = ({ title, subtitle, body, image }) => {
  const classes = useStyles();
  return (
    <ImageContent image={image} imageAlt={title} minHeight={90}>
      <Box className={classes.root}>
        <Typography variant="h4" className={classes.greeting}>
          Hello!
        </Typography>
        <Typography variant="h3" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h6" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <Typography variant="body1" className={classes.bodyText}>
          {body[0]}
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          role="link"
          onClick={() => {
            navigate(`/about`);
          }}
        >
          See more details
        </Button>
      </Box>
    </ImageContent>
  );
};

export default Introduction;
