import React from 'react';
import {
  makeStyles,
  Box,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { CardActionArea } from 'gatsby-theme-material-ui';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { IService } from '@/types';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
  card: {
    height: `100%`,
    borderRadius: `10px`,
  },
  cardActionArea: {
    height: `100%`,
    transition: `0.3s`,
    opacity: 1,
    width: `100%`,
    '&:hover': {
      background: `${theme.palette.secondary.main}4d`,
    },
  },
  cardContent: {
    alignItems: `center`,
    display: `flex`,
    flexDirection: `column`,
    padding: theme.spacing(3, 1.5),
    [theme.breakpoints.down(`xs`)]: {
      padding: theme.spacing(3, 4),
    },
  },
  description: {
    fontSize: theme.spacing(2.5),
    maxWidth: theme.spacing(33),
    lineHeight: theme.spacing(0.23),
    [theme.breakpoints.down(`md`)]: {
      fontSize: theme.spacing(2.3),
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(2),
      maxWidth: theme.spacing(40),
    },
  },
  icon: {
    fontSize: theme.spacing(5),
    color: theme.palette.secondary.main,
  },
  iconWrapper: {
    alignItems: `center`,
    background: `white`,
    borderRadius: theme.spacing(50),
    display: `flex`,
    height: theme.spacing(12),
    justifyContent: `center`,
    marginBottom: theme.spacing(5),
    width: theme.spacing(12),
  },
  title: {
    marginBottom: theme.spacing(3),
    fontSize: theme.spacing(3.2),
    textAlign: `center`,
    textTransform: `uppercase`,
    [theme.breakpoints.down(`md`)]: {
      fontSize: theme.spacing(3),
    },
    [theme.breakpoints.down(`xs`)]: {
      fontSize: theme.spacing(2.7),
    },
  },
}));

interface ServiceCardProps extends IService {
  delay?: number;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  icon,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.card}>
      <CardActionArea className={classes.cardActionArea} to="/services">
        <CardContent className={classes.cardContent}>
          <Box className={classes.iconWrapper}>
            <GatsbyImage image={getImage(icon)} alt={title} />
          </Box>
          <Typography className={classes.title} variant="h2">
            {title}
          </Typography>
          <Typography className={classes.description} variant="h6">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Box>
  );
};

interface ServiceCardsProp {
  services: ServiceCardProps[];
}

const ServiceCards: React.FC<ServiceCardsProp> = ({ services }) => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="lg">
      <Grid container justify="center" alignItems="stretch" spacing={2}>
        {services.map(({ title, description, icon }) => (
          <Grid key={title} item xs={12} sm={6} md={3}>
            <ServiceCard
              title={t(title)}
              description={t(description)}
              icon={icon}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default ServiceCards;
