import React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ActionCards, Introduction, ServiceCards } from '@/components/home';
import { Banner, Header, Layout, Section, Helmet, Gallery } from '@/components';
import { IContent, IPageSection, IService } from '@/types';
import { useCompanyInfo } from '@/hooks';

const useStyles = makeStyles((theme) => ({
  introduction: {
    background: theme.palette.grey.main,
    zIndex: 0,
  },
  noPadding: {
    padding: 0,
  },
  noXPadding: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  serviceCards: {
    backgroundColor: theme.palette.secondary.light,
  },
}));

const Home: React.FC<PageProps> = ({ data }) => {
  const { t } = useTranslation();
  const { phoneNumber } = useCompanyInfo();
  const classes = useStyles();
  const sections = data.page.children;
  const header = sections[0] as IPageSection;
  const actionCards = sections[1] as IPageSection;
  const introduction = sections[2].children[0] as IContent;
  const services = sections[3] as IPageSection;
  const galleryImages = sections[4].children[0].children as IGatsbyImageData[];
  const banner = sections[5].children[0] as IContent;

  return (
    <main>
      <Helmet page="Home" />
      <Layout>
        <Header
          isHome
          title={t(header.title)}
          subtitle={t(header.subtitle)}
          images={header.children}
        />
        <Section title={t(actionCards.title)} className={classes.noXPadding}>
          <ActionCards actionCards={actionCards.children} />
        </Section>
        <Section className={`${classes.noXPadding} ${classes.introduction}`}>
          <Introduction
            title={t(introduction.title)}
            subtitle={t(introduction.subtitle)}
            body={introduction.body.map(t)}
            image={getImage(introduction.image)}
          />
        </Section>
        <Section
          title={t(services.title)}
          subtitle={t(services.subtitle)}
          className={classes.serviceCards}
        >
          <ServiceCards services={services.children as IService[]} />
        </Section>
        <Section>
          <Gallery images={galleryImages} />
        </Section>
        <Section className={classes.noPadding}>
          <Banner
            image={banner.image}
            pageLink="/contact"
            text={t(banner.title).replace(`\${phoneNumber}`, phoneNumber)}
          />
        </Section>
      </Layout>
    </main>
  );
};

export default Home;

export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page(id: { eq: "Home" }) {
      children {
        ... on Header {
          title
          subtitle
          children {
            ... on File {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
        ... on Section {
          title
          subtitle
          children {
            ... on Content {
              title
              subtitle
              image {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
              children {
                ... on File {
                  childImageSharp {
                    gatsbyImageData(placeholder: TRACED_SVG)
                  }
                }
              }
              body
            }
            ... on Service {
              title
              description
              icon {
                childImageSharp {
                  gatsbyImageData(placeholder: TRACED_SVG)
                }
              }
            }
          }
        }
      }
    }
  }
`;
